<template>
    <div class="functions">

        <v-toolbar class="custom-toolbar mb-4" flat dense>
            <v-btn icon>
                <v-icon size="40" @click="goBack">
                    mdi-arrow-left-bold-circle
                </v-icon>
            </v-btn>
            <v-toolbar-title class="custom-toolbar-title">
                <!-- Align "Functions" title next to the logo -->
                {{ 'Functions' }}
            </v-toolbar-title>
            <img :src="logo" class="toolbar-icon" />
        </v-toolbar>

        <!-- Pricing Options Section -->
        <div class="plans-container functions-container">
            <div class="plans-header">
                <h1>Functions Options</h1>
                <p style="color: #555;">Choose the perfect plan for your business needs</p>
            </div>
            <div class="plans">
                <!-- Starter Plan -->
                <div class="plan" v-for="plan in plans" :key="plan.id" :class="{ 'business-plan': plan.id === 2 }">
                    <div v-if="plan.id === 2" class="most-popular-badge">Most Popular</div>
                    <h2>{{ plan.name }}</h2>
                    <ul style="color: #555;">
                        <li v-for="feature in plan.features" :key="feature">
                            <v-icon left class="feature-tick">mdi-check-circle-outline</v-icon>
                            {{ feature }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { getToolbarLogoImage } from '@/assets/Branding/branding';

export default defineComponent({
    name: 'Functions',
    setup() {
        const logo = ref('');
        const router = useRouter();

        const plans = ref([
            {
                id: 1,
                name: 'Starter',
                features: [
                    'App Level (PWA)',
                    'Authentication',
                    'Communication Management',
                    'Bookings Management',
                    'Feedbacks Management',
                    'User Profile',
                    'App Settings'
                ]
            },
            {
                id: 2,
                name: 'Business',
                features: [
                    'App Level (PWA, Android, iOS)',
                    'Authentication',
                    'Communication Management',
                    'Bookings Management',
                    'Workspace Management',
                    'Chat Functionality',
                    'Analytics',
                    'Feedbacks Management',
                    'User Profile',
                    'App Settings',
                    'Incidents Management',
                    'Users Management'
                ]
            }
        ]);

        onMounted(async () => {
            logo.value = await getToolbarLogoImage();
        });

        const goBack = () => {
            router.go(-1);
        };

        return {
            logo,
            plans,
            goBack
        };
    }
});
</script>

<style scoped>
.functions {
    padding: 1rem;
}

.custom-toolbar {
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    color: #333;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
}

.custom-toolbar-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    color: #333;
    margin-left: 10px;
    /* Spacing between logo and title */
}

.toolbar-icon {
    height: 30px;
}

.plans-container {
    margin-top: 2rem;
    text-align: center;
}

.plans-header {
    margin-bottom: 2rem;
}

.plans {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.5rem;
    /* Adds space between the cards */
}

.plan {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 250px;
    text-align: left;
    /* Left align the text inside the card */
    position: relative;
    /* For positioning the "Most Popular" badge */
}

.business-plan {
    border: 2px solid #007BFF;
    /* Blue border for the Business card */
}

.most-popular-badge {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #007BFF;
    color: white;
    padding: 5px 10px;
    border-radius: 50px;
    font-size: 0.9rem;
}

.plan h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.plan ul {
    list-style: none;
    padding: 0;
}

.plan li {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.feature-tick {
    color: lightblue;
    margin-right: 10px;
    /* Spacing between tick and feature */
}

@media (max-width: 768px) {
    .plans {
        flex-direction: column;
        align-items: center;
    }

    .plan {
        width: 100%;
        max-width: 400px;
    }
}
</style>